export default {
  green: {
    0: '#0E7E3D',
    1: '#168B46',
    2: '#13AA52',
    3: '#1CC061',
    4: '#89E5B3',
    5: '#CCFFE1',
    6: '#EFFEF6',
  },

  gray: {
    0: '#303434',
    1: '#464C4F',
    2: '#626668',
    3: '#818487',
    4: '#9FA1A2',
    5: '#BABDBE',
    6: '#D7DBDB',
    7: '#E8E9E9',
    8: '#F4F6F6',
  },

  mongodb: {
    white: '#FFFFFF',
    green: '#13AA52',
    blue: '#43B1E5',
    navyBlue: '#1D6C92',
    backgroundBlue: '#E8F5FB',
    selectBlue: '#5897FB',
    red: '#ED271C',
    lightRed: '#FFEAEA',
    alertRed: '#EF4C4C',
    yellow: '#FFB618',
    slate: '#4D5359',
    cautionOrange: '#FFBC91',
    errorBackground: '#FDD0D1',
    validGreen: '#F1F2ED',
  },
};
